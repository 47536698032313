import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant'
const service = axios.create({
    baseURL: '', // 开发环境
    timeout: 5000,
})

service.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code !== 0) {
            Toast(res.msg)
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default {
    post (url, data, json) {
        return new Promise((resolve, reject) => {
            service({
                method: 'post',
                url,
                data: json ? data : qs.stringify(data),
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    get (url, data) {
        return new Promise((resolve, reject) => {
            service({
                method: 'get',
                url,
                params: data,
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    postImport (url, data) {
        return new Promise((resolve, reject) => {
            service({
                method: 'post',
                url,
                data: data,
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
