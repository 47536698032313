import http from './request'
const post = (url, data, json) => {
    return http.post(url, data, json)
}
const get = (url, params) => {
    return http.get(url, params)
}
const postImport = (url, data) => {
    return http.postImport(
        url,
        data
    )
}
export { post, get, postImport }
