<template>
    <div class="allBody">
        <div class="addFriendBody">
            <!-- <van-nav-bar class="NavBar" title="批量添加好友" left-arrow @click-left="onClickLeft" /> -->
            <van-tabs @change="onClick">
                <van-tab name="0">
                    <template #title>
                        <span>待添加</span>
                        <span>（{{noAddLength}}）</span>
                    </template>
                    <van-cell-group>
                        <van-cell title="单元格" v-for="(item,idx) in noAdd" :id="item.id" :key="idx" @click="clickCell(item)">
                            <template #title>
                                <span class="custom-title">{{item.phone}}</span>
                            </template>
                            <template #default>
                                <span style="color:#6682FB;" v-if="item.friendStatus == 1">已是好友</span>
                                <span style="color:#6682FB;margin-left:10px;" @click="addcope">复制</span>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-tab>
                <!-- <van-tab name="1">
                    <template #title>
                        <span>已添加未成功</span>
                        <span>（{{addUnsuccessfulLength}}）</span>
                    </template>
                    <van-cell-group>
                        <van-cell title="单元格" v-for="(item,idx) in addUnsuccessful" :id="item.id" :key="idx">
                            <template #title>
                                <span class="custom-title">{{item.phone}}</span>
                            </template>
                            <span style="color:#ccc;" v-if="item.friendStatus == 0">复制</span>
                            <span style="color:#6682FB;" v-if="item.friendStatus == 1">已是好友</span>
                        </van-cell>
                    </van-cell-group>
                </van-tab> -->
                <van-tab name="2">
                    <template #title>
                        <span>添加成功</span>
                        <span>（{{addSuccessLength}}）</span>
                    </template>
                    <van-cell-group>
                        <van-cell title="单元格" v-for="(item,idx) in addSuccess" :id="item.id" :key="idx">
                            <template #title>
                                <span class="custom-title">{{item.phone}}</span>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-tab>
            </van-tabs>
            <p style="font-size:12px;color:#ccc;text-align:center;">暂无更多数据</p>
        </div>
    </div>
</template>

<script>
import { getCallAssignStaffList } from '@/apiModules/api/iAnswer'
import { Toast } from 'vant'
export default {
    components: {},
    props: {},
    data () {
        return {
            activeName: '0',
            noAdd: [],
            addUnsuccessful: [],
            addSuccess: [],
            noAddLength: '',
            addUnsuccessfulLength: '',
            addSuccessLength: '',
        }
    },
    watch: {},
    computed: {},
    methods: {
        onClickLeft () { },
        addcope () {
            Toast('复制成功')
        },
        clickCell (item) {
            const input = document.createElement('input')
            document.body.appendChild(input)
            input.setAttribute('value', item.phone)
            input.select()
            if (document.execCommand('copy')) {
                document.execCommand('copy')
            }
            document.body.removeChild(input)
        },
        onClick (name) {
            this.activeName = name
            if (this.activeName == 0) {
                let params = {
                    staffId: this.$route.query.staffId,
                    status: 0
                }
                getCallAssignStaffList(params).then(res => {
                    this.addUnsuccessful = res.data
                    this.noAddLength = res.data.length
                })
            }
            if (this.activeName == 2) {
                let params = {
                    staffId: this.$route.query.staffId,
                    status: 2
                }
                getCallAssignStaffList(params).then(res => {
                    this.addSuccess = res.data
                    this.addSuccessLength = res.data.length
                })
            }
        },
        getList () {
            let paramsed = {
                staffId: this.$route.query.staffId,
                status: this.activeName
            }
            getCallAssignStaffList(paramsed).then(res => {
                this.noAdd = res.data
                this.noAddLength = res.data.length
            })
            let paramss = {
                staffId: this.$route.query.staffId,
                status: 2
            }
            getCallAssignStaffList(paramss).then(res => {
                this.addSuccess = res.data
                this.addSuccessLength = res.data.length
            })
        }
    },
    created () { },
    mounted () {
        this.getList()
    }
}
</script>
<style lang="scss" scoped>
.allBody {
    width: 100%;
    .addFriendBody {
        @media only screen and (min-width: 720px) {
            width: 720px;
            height: 100vh;
            margin: 0 auto;
        }
    }
    .addFriendBody {
        height: 100vh;
        .NavBar {
            background-color: #3974c7;
            /deep/.van-nav-bar__title {
                color: #fff;
            }
            /deep/.van-icon {
                color: #fff;
            }
        }
        /deep/.van-cell {
            padding: 1% 2%;
            line-height: 30px;
        }
        /deep/.van-tabs__content {
            padding: 0 2%;
        }
        /deep/.van-tabs__wrap {
            border-bottom: 1px solid #f0f0f0;
        }
        /deep/.van-nav-bar__content {
            // height: 80px;
        }
    }
}
</style>